import React from "react";

import { DataGrid } from '@mui/x-data-grid';
import { Box, Grid, Card, CardContent,  } from '@mui/material';

import Top10URL from '../url/top10_url'
import Header from "../header/header";
import logo from '../../assets/images/celtech-title.png';
const columns = [
    {
        field: 'urlId',
        headerName: ' ID URL',
        description: 'Codigo Unico para acorta la Url',
        sortable: true,
        width: 210,
        valueGetter: (params) =>
            `${params.row.urlId || ''}`,
    },
    { field: 'origUrl', headerName: 'Original Url', width: 400 },
    { field: 'shortUrl', headerName: 'Url Corto', width: 330 },
    { field: 'host', headerName: 'Host', width: 150 },
    { field: 'userAgent', headerName: 'User Agent', width: 200 },
    { field: 'clicks', headerName: 'Clicks', type: 'number', width: 70, },
    { field: 'date', headerName: 'Fecha y Hora', width: 200 },

];

class TopURL extends React.Component {

    constructor() {
        super();
        this.state = {
            rows: [],
            pageSize: 10,
            session: false,

        }
    }

    
    componentWillUnmount() {
        this.props.checkToken();
    };

    ChangeData=(value)=>{
        this.setState({
            rows: value,
        });
        
    }

    render() {
        return (
            <React.Fragment>
                <Header stateSesion={this.state.session} />
                <div className="App-header" >
                    <img src={logo} className="App-img" alt="logo" />

                    <h3>Top de las URL mas visitadas</h3>
                    <Box width="100%">
                        <Grid container spacing={2} >
                        <Grid  item xs={12} sm={12} md={3}> 
                        </Grid>    
                            <Grid  item xs={12} sm={12} md={6}>                                
                                <Card>
                                    <CardContent >
                                        <Top10URL data={this.ChangeData} />
                                    </CardContent>
                                </Card>
                                </Grid>
                                
                            </Grid>
                    </Box>
                    <Box width="100%">
                        <Grid>
                            <Grid item  sx={12} md={12}>
                               
                                    <Card>
                                        <CardContent>
                                            <Box style={{ height: 631, width: '100%' }}>
                                                <DataGrid
                                                    rows={this.state.rows}
                                                    columns={columns}
                                                    getRowId={(row) => row._id}
                                                    pageSize={this.state.pageSize}
                                                />
                                            </Box>


                                        </CardContent>
                                    </Card>
                                {/* </Container> */}

                            </Grid>
                        </Grid>
                    </Box>
                </div>

            </React.Fragment>
        )
    }

}

export default TopURL;