
import jwt_decode from "jwt-decode";

 function IsAuthenticated(){

    let token = localStorage.getItem('token');
    
    if(token !== undefined && token !== null && token !== ''){
        
        //verificar sino ha expirado
        const decoded = jwt_decode(token);
        const timenow = Math.floor( new Date().getTime()/1000);

        if(decoded.exp > timenow )
        {
            return true;
        }
    }

    return false;
}

export function IsExpires(){

    let token = localStorage.getItem('token');
    
    if(token !== undefined && token !== null && token !== ''){
        
        //verificar sino ha expirado
        const decoded = jwt_decode(token);
        const timenow = Math.floor( new Date().getTime()/1000);  

        if(decoded.exp <= timenow )
        {
            return true;
        }
        
      
    }
    return false;
}

export function DisableSession(){
    if(localStorage.getItem('token')){
       
        localStorage.removeItem('token');
        localStorage.removeItem('errorMessage');
        localStorage.removeItem('errorLogin');
    }

}

export function getToken(){
    let token = localStorage.getItem('token');
   
    if(token !== undefined && token !== null && token.length > 0){
        return token;

    }

    return null;
}


export function getErrorMessage(){
    let token = localStorage.getItem('errorMessage');

    return token;
}


export function getErrorlist(){
    let token = localStorage.getItem('errorLogin');

    return token;
}

export function saveToken(token){
        localStorage.setItem('token', token);
    return true;
}


export function saveError(message, errors){
    
    localStorage.setItem('errorMessage',   message);
    localStorage.setItem('errorLogin', JSON.stringify( errors));
    
    return true;
}

export function deleteToken(){

        localStorage.removeItem('token');

    return true;
}


export default IsAuthenticated;