import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Navigate } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

class SessionExp extends Component {
    constructor(){
        super();
        this.state ={
            open:true,
            status:false,
        }
    }

    handleClick = () => {
        this.setState({open:true});
      };
    
      handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({open:false});
      };

      goHome=() => {
       
        this.setState({ status: true });

    }
    render() {
        return (
            <React.Fragment>

                {
                            this.state.status &&
                            <Navigate replace to="/auth/login" />
                }

                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity="error" sx={{ width: '100%' }}
                    action={
                        <Button color="inherit" size="small" onClick={this.goHome}>
                          Iniciar Sesion
                        </Button>
                      }>
                        La session ha Caducado, por favor inicie session nuevamente.
                    </Alert>
                </Snackbar>

            </React.Fragment>
        )
    }
}

export default SessionExp;