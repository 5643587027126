import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import Header from "../../header/header";
import logo from '../../../assets/images/celtech-title.png';

class Out extends Component {

    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="App-header" >
                    <img src={logo} className="App-img" alt="logo" />                   

                    <h1>Te extrañaremos</h1>
                    <h3>Experamos que vuelva pronto</h3>
                    <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                        <NavLink className="color-white" to='/auth/login' >
                            Iniciar Sesion
                        </NavLink>
                    </Button>

                </div>
            </React.Fragment>
        )
    }
}

export default Out;