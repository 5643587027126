import React, { Component } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js/auto';

import axios from 'axios';
import { Bar } from 'react-chartjs-2';

import {getBaseUrl,getHosts} from '../../service/apiParameters';
import HeaderInyected from '../../service/headers'
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
)

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
}

class Hosts extends Component {
    constructor() {
        super();
        this.state = {
            chartData: {},
            month: new Date()
        }
    }

    componentWillMount() {       
        this.validate(this.state.month);
    }
    
    validate = (month) => {       
        
        
            
        let quantity = [];
        let labels = [];
        axios.get(getBaseUrl() + getHosts() + `?date=${month.toISOString().slice(0, 10)} `, HeaderInyected())
            .then(response => {
                if (response.data.code === 200) {
                    
                    const data = response.data.results;

                    data.forEach(ele => {
                        quantity.push(parseInt(ele.count));
                        labels.push(ele._id);

                    });                    

                    this.setState({
                        chartData: {
                            datasets: [
                                {
                                 label: 'Fuentes o generadores',
                                  data: quantity,
                                  borderColor: 'rgb(53, 162, 235)',
                                  backgroundColor: 'rgb(75, 192, 192)',

                                  pointRadius:6,
                                },
                            ],
                            labels,
                        }
                    }
                    )
                }

            }).catch(error => {
                localStorage.setItem('errorMessage', error.response.data.message);
                localStorage.setItem('errorLogin', JSON.stringify(error.response.data.errors));

                /*this.setState(
                    {
                        textErrorApi:true,
                        status:false  
                    });*/
            });
        
    }
    ChangeMonth(newValue){
        this.validate(newValue);
        this.setState({
           month: newValue
        })
   }
    render() {
        return (
            <div id='hosts'>
                <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                            label="MES Seleccionado"
                            value={this.state.month}
                            onChange={(newValue) => {
                                this.ChangeMonth(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            views={['month', 'year']}
                        />
                    </LocalizationProvider>
                </FormControl>
                
                <h4> Antifitiones </h4>
                {
                    Object.keys(this.state.chartData).length &&
                    <Bar data={this.state.chartData} options={options} />
                }

            </div>
        );
    }





}

export default Hosts;