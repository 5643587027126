import React, { Component } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js/auto';
import axios from 'axios';


import { Bar } from 'react-chartjs-2';

import {getBaseUrl,getAgent} from '../../service/apiParameters'
import HeaderInyected from '../../service/headers'

import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
)

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
}

class Agents extends Component {
    constructor() {
        super();
        this.state = {
            chartData: {},
            month: new Date()
        }
    }

    componentDidMount() {       
        this.validate(this.state.month);
    }
    
    validate = (month) => {       
            
        let quantity = [];
        let labels = [];
        axios.get( getBaseUrl()+getAgent() + `?date=${month.toISOString().slice(0, 10)} `, HeaderInyected())
            .then(response => {
                if (response.data.code === 200) {
                    
                    const data = response.data.results;

                    data.forEach(ele => {
                        quantity.push(parseInt(ele.count));
                        labels.push(ele._id);

                    });

                    this.setState({
                        chartData: {
                            datasets: [
                                {
                                 label: 'Ordenados por Agentes',
                                  data: quantity,
                                  borderColor: 'rgb(255, 99, 132)',
                                  backgroundColor: 'rgba(255, 206, 86, 0.6)',
                                  pointRadius:6,
                                },
                            ],
                            labels,
                        }

                    }



                    )


                }

            }).catch(error => {
                localStorage.setItem('errorMessage', error.response.data.message);
                localStorage.setItem('errorLogin', JSON.stringify(error.response.data.errors));

                /*this.setState(
                    {
                        textErrorApi:true,
                        status:false  
                    });*/
            });
        
    }

    ChangeMonth(newValue){
        this.validate(newValue);
        this.setState({
           month: newValue
        })
   }

    render() {
        return (
            <div id='agents'>
                <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                            label="MES Seleccionado"
                            value={this.state.month}
                            onChange={(newValue) => {
                                this.ChangeMonth(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            views={['month', 'year']}
                        />
                    </LocalizationProvider>
                </FormControl>

                <h4> Agentes</h4>
                {
                    Object.keys(this.state.chartData).length &&
                    <Bar data={this.state.chartData} options={options} />
                }

            </div>
        );
    }

}

export default Agents;