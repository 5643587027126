import React from "react";

import { DataGrid } from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import axios from 'axios';

import { getBaseUrl, getAgent, getHosts, getUrlFilterGenerate, getAllUrlGenerate } from '../../service/apiParameters';
import { Card, CardContent, Grid,  Select, MenuItem, InputLabel } from "@mui/material";
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import  {  getErrorlist, } from '../../service/AuthService';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Header from "../header/header";
import logo from '../../assets/images/celtech-title.png';
import HeaderInyected from "../../service/headers";
const columns = [

  {
    field: 'urlId',
    headerName: ' ID URL',
    description: 'Codigo Unico para acorta la Url',
    sortable: true,
    width: 210,
    valueGetter: (params) =>
      `${params.row.urlId || ''}`,
  },

  { field: 'origUrl', headerName: 'Original Url', width: 400 },
  { field: 'shortUrl', headerName: 'Url Corto', width: 330 },
  { field: 'host', headerName: 'Host', width: 150 },

  { field: 'userAgent', headerName: 'User Agent', width: 200 },

  { field: 'clicks', headerName: 'Clicks', type: 'number', width: 70, },
  
  { field: 'userId', headerName: 'ID Usuario', width: 240 },
  { field: 'date', headerName: 'Fecha y Hora', width: 200 },

];

class shortUrlTable extends React.Component {

  constructor() {
    super();
    this.state = {
      //table
      rows: [],
      pagination: {},
      pageSize: 10,
      page: 0,
      //filter
      hosts: [],
      agents: [],


      //control     

      status: false,
      statusToken: false,
      statusCredentials: false,
      textErrorApi: false,
      useFilter: false,
      sesion:false,
      //initial
      dateBegin: new Date(), //).format('YYYY-MM-DD')  ,
      dateEnd: new Date(),
      agent: null,
      host: null,

      textErrorMessage: null,
      listErrorAPI:null,
    }

    this.host = React.createRef();
    this.agent = React.createRef();
    this.dateBegin = React.createRef();
    this.dateEnd = React.createRef();



  }




  componentDidMount() {

    
    this.GetAllData(this.state.pageSize, this.state.page);
    this.GetHosts();
    this.GetAgents();
  }

  GetAllData = (size, page) => {
    
      axios.get(getBaseUrl() + getAllUrlGenerate() + '?size=' + size + '&page=' + page, HeaderInyected())
        .then(response => {

          if (response.data.code === 200) {

            const data = response.data.results;
            const pagination = response.data.pagination;

            data.forEach(ele => {
              ele.date = new Date(ele.date).toLocaleString();
            });

            this.setState({
              rows: data,
              pagination: pagination             
            }
            );

          }

        }).catch(error => {
          
          //localStorage.setItem('errorMessage', error.response.data.message);
          //localStorage.setItem('errorLogin', JSON.stringify(error.response.data.errors));

          this.setState(
              {
                  textErrorApi:true,
                  status:false,
                  textErrorMessage: error.response.data.message,
                  listErrorAPI: JSON.stringify(error.response.data.errors),

              });
        });

    
  }


  GetHosts = () => {
    
      axios.get(getBaseUrl() + getHosts(), HeaderInyected())
        .then(response => {

          if (response.data.code === 200) {
            const data = response.data.results;

            this.setState({
              hosts: data,
            });
            
          }
        }).catch(error => {
          this.setState(
            {
                textErrorApi:true,
                status:false,
                textErrorMessage: error.response.data.message,
                listErrorAPI: JSON.stringify(error.response.data.errors),

            });
          
        });

    
  }


  GetAgents = () => {
   
      axios.get(getBaseUrl() + getAgent(), HeaderInyected())
        .then(response => {

          if (response.data.code === 200) {
            const data = response.data.results;

            this.setState({
              agents: data,
            });

           
        }
        }).catch(error => {
         

          this.setState(
            {
                textErrorApi:true,
                status:false,
                textErrorMessage: error.response.data.message,
                listErrorAPI: JSON.stringify(error.response.data.errors),

            });
        });
    
  }


  formFilter = (e) => {
    e.preventDefault();
    const size = this.state.pageSize;
    const page = this.state.page;
    this.searchingData(size, page);
  }

  searchingData = (size, page) => {

    let filter = {}

    if(this.state.dateBegin){
      filter = {
        ...filter,
        dateBegin: this.state.dateBegin.toISOString().slice(0, 10),

      }
    }

    if(this.state.dateEnd){
      filter = {
        ...filter,
        dateEnd: this.state.dateEnd.toISOString().slice(0, 10),

      }
    }

    if (this.state.host) {
      filter = {
        ...filter,
        host: this.state.host ? this.state.host.target.value : this.state.host,

      }
    }

    if (this.state.agent) {
      filter = {
        ...filter,
        userAgent: this.state.agent ? this.state.agent.target.value : this.state.host,

      }
    }


    
      axios.post(getBaseUrl() + getUrlFilterGenerate() + '?size=' + size + '&page=' + page, filter, HeaderInyected())
        .then(response => {
          if (response.data.code === 200) {
            const data = response.data.results;
            data.forEach(ele => {
              ele.date = new Date(ele.date).toLocaleString();
            });
            const pagination = response.data.pagination;

            this.setState({
              rows: data,
              pagination: pagination,
              useFilter: true,
            });


          }

          this.setState(
            {
              textErrorApi: false,
              status: true

            });

        }).catch(error => {

          localStorage.setItem('errorMessage', error.response.data.message);
          localStorage.setItem('errorLogin', JSON.stringify(error.response.data.errors));

          this.setState(
            {

              textErrorApi: true,
              status: false

            });
        });
    
  };







  resetForm = () => {
    this.setState(
      {
        status: false,
        statusToken: false,
        statusCredentials: false,
        textErrorApi: false,
        useFilter: false,
        dateBegin: null,
        dateEnd: null,
      });

    this.GetAllData(this.state.pageSize, 0);

  }


  validateCallUrl = (size, page) => {
    if (this.state.useFilter) {
      this.searchingData(size, page);
    }
    else {
      this.GetAllData(size, page);
    }
  }



  setPageSize = (size) => {    
    this.setState({
      pageSize: size
    });
    this.validateCallUrl(size, this.state.page);

  }

  setPage = (page) => {   
    this.setState({
      page: page
    }
    )
    this.validateCallUrl(this.state.pageSize, page);
  }

  componentWillUnmount() {
    //verifica estado de token al salir del componente
    this.props.checkToken();
  };

  render() {

    return (
      <React.Fragment>
        <Header stateSesion={this.state.session} />
        <div className="App-header" >
          <img src={logo} className="App-img" alt="logo" />
          <h3>Detalle de URL Generadas</h3>
          <Card >
            <CardContent>
              <form onSubmit={this.formFilter}>
                <Box>
                  <Grid container spacing={1} >
                    <Grid container item xs={12} sm={12} md={12}>

                      {
                        this.state.textErrorApi ? (
                          <React.Fragment>
                            <code className="color-red">  {getErrorlist()} </code>
                          </React.Fragment>
                        ) : (
                          <FormLabel id="demo-error-radios">Seccione los filtros necesarios...</FormLabel>
                        )
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}  >
                      <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                        <InputLabel id="host-select-label">Host</InputLabel>
                        <Select
                          labelId="host-select-label"
                          id="host-select"
                          //value={this.state.host?this.state.host.current.value:''}
                          label="Hosts"
                          //onChange={handleChange}
                          onChange={(newValue) => {
                            this.setState({ host: newValue });
                          }}
                        >
                          <MenuItem >Seleccione una Opcion</MenuItem >
                          {
                            this.state.hosts &&
                            this.state.hosts.map(host => {
                              return <MenuItem value={host._id} key={host._id}>{host._id}</MenuItem>
                            })
                          }
                        </Select>
                        <FormHelperText >Fuentes Generadoras</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                      <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                        {
                          this.state.agents &&
                          <React.Fragment>
                            <InputLabel id="agent-select-label">User Agents</InputLabel>
                            <Select
                              labelId="agent-select-label"
                              id="agent-select"
                              ///value={age}
                              label="Agents"
                              //onChange={handleChange}
                              onChange={(newValue) => {
                                this.setState({ agent: newValue });
                              }}
                            >
                              <MenuItem >Seleccione una Opcion</MenuItem >
                              {this.state.agents?.map((agent) => {
                                return <MenuItem value={agent._id}>{agent._id}</MenuItem >;
                              })}
                            </Select>
                          </React.Fragment>
                        }
                        <FormHelperText >Aplicaciones generadoras</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} >
                      <FormControl sx={{ m: 1 }} variant="standard" fullWidth>

                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                          <DatePicker
                            label="Fecha de Inicio"
                            value={this.state.dateBegin}
                            onChange={(newValue) => {
                              this.setState({ dateBegin: newValue });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} >
                      <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Fecha Final"
                            value={this.state.dateEnd}
                            onChange={(newValue) => {
                              this.setState({
                                dateEnd: newValue
                              })
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </FormControl>

                    </Grid>
                    <Grid xs={12} sm={6} md={1}>
                      <FormControl sx={{ m: 1 }} fullWidth>
                        <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                          Filtrar
                        </Button>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} md={1}>
                      <FormControl sx={{ m: 1 }} fullWidth>
                        <Button sx={{ mt: 1, mr: 1 }} type="button" onClick={this.resetForm} variant="outlined">
                          Deshacer
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </CardContent>
          </Card>
          <Box width="100%" >
            <Grid maxWidth="lx">

              <Grid  item  sx={12} md={12}>
                <Card >
                  <CardContent style={{ height: 640}}>
                    <DataGrid
                      rows={this.state.rows}
                      columns={columns}
                      getRowId={(row) => row._id}

                      page={this.state.page}
                      paginationMode="server"

                      onPageChange={(newPage) => this.setPage(newPage)}
                      pageSize={this.state.pageSize}
                      onPageSizeChange={(newPage) => this.setPageSize(newPage)}

                      rowsPerPageOptions={[10, 25, 50, 100]}
                      pagination

                      rowCount={this.state.pagination.total}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </div>
      </React.Fragment>
    )
  }
}

export default shortUrlTable;