import React, { Component } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js/auto';


import axios from 'axios';

import { Bar } from 'react-chartjs-2';

import { getBaseUrl, getTop10 } from '../../service/apiParameters';
import HeaderInyected from '../../service/headers';


import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
)

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
}



class Top10URL extends Component {


    constructor() {
        super();
        this.state = {
            chartData: {},
            month: new Date(),            
        }
    }

    componentWillMount() {       
        this.validate(this.state.month);
    }

    validate = (month) => {      
            
        let clicks = [];
        let labels = [];
        axios.get(getBaseUrl() + getTop10() +  `?date=${month.toISOString().slice(0, 10)}`, HeaderInyected())
            .then(response => {
                if (response.data.code === 200) {
                    
                    const data = response.data.results;
                    // manda la señal a otro compenente sobre cambio
                    this.props.data(data);

                    data.forEach(ele => {
                        ele.date =  ele.date = new Date(ele.date).toLocaleString();
                        clicks.push(parseInt(ele.clicks));
                        labels.push(ele.urlId);

                    });

                    this.setState({
                        chartData: {
                            datasets: [
                                {
                                 label: 'Los mas visitados',
                                  data: clicks,
                                  borderColor: 'rgb(53, 162, 235)',
                                  backgroundColor:  'rgba(153, 102, 255, 0.7)',
                                  pointRadius:6,
                                },
                            ],
                            labels,
                        },
                    })


                }

            }).catch(error => {
                localStorage.setItem('errorMessage', error.response.data.message);
                localStorage.setItem('errorLogin', JSON.stringify(error.response.data.errors));

                this.setState(
                    {
                        textErrorApi:true,
                        status:false  
                    });
            });
        



    }
    ChangedMonth(value){
        
        this.setState({ month: value });
        this.validate(value);

        
        
       


    }


    render() {
        return (
            <div id='Top10'>
                 <FormControl sx={{ m: 1 }} variant="standard" fullWidth>

                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DatePicker
                        label="MES Seleccionado"
                        value={this.state.month}
                        onChange={(newValue) => {
                        this.ChangedMonth(newValue );
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        views={['month', 'year']} 
                    />
                    </LocalizationProvider>
                </FormControl>
                <h4> Top 10 - Click</h4>
                {
                    Object.keys(this.state.chartData).length &&
                    <Bar data={this.state.chartData} options={options} />
                    
                }

            </div>
        );
    }
}

export default Top10URL;