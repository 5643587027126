import React from "react";

import { DataGrid } from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import axios from 'axios';

import { getBaseUrl, getUserFilter, getListUsers, getCreateUser } from '../../service/apiParameters';
import { Card, CardContent, Grid, InputLabel } from "@mui/material";
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { getErrorlist, getToken, } from '../../service/AuthService';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Header from "../header/header";
import logo from '../../assets/images/celtech-title.png';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import FormGroup from '@mui/material/FormGroup';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const columns = [

  {
    field: 'email',
    headerName: ' Correo electronico',
    description: 'email para iniciar sesion',
    sortable: true,
    width: 210,
    valueGetter: (params) =>
      `${params.row.email || ''}`,
  },

  { field: 'date', headerName: 'Fecha Creacion', width: 400 },
  { field: 'dateModified', headerName: 'Fecha Ultima Modificacion', width: 330 },


  { field: 'tokenAccess', headerName: 'Token', width: 200 },
  { field: 'isAdmin', headerName: 'Es Administrador?', width: 200 },
  { field: 'isEnabled', headerName: 'Es activo?', width: 200 },
  { field: 'dateDisabled', headerName: 'Fecha Desactivacion', width: 330 },

];


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Cuentas extends React.Component {

  constructor() {
    super();
    this.state = {
      //table
      rows: [],
      pagination: {},
      pageSize: 10,
      page: 0,
      //filter
      hosts: [],
      agents: [],
      showPassword: false,
      showRePassword: false,


      //control     

      status: false,
      statusToken: false,
      statusCredentials: false,
      textErrorApi: false,
      useFilter: false,
      //initial
      dateBegin: new Date(), //).format('YYYY-MM-DD')  ,
      dateEnd: new Date(),
      email: null,
      tokenAcces: null,
      openDialog: false,
      notMatchPaswword: false,
      openSnackBar: false,
      textSuccesCreated: null,
      textErrorCreated: null,
      textListErrorCreated: null,
    }

    this.dateBegin = React.createRef();
    this.dateEnd = React.createRef();

    this.tokenAccesRef = React.createRef();
    this.emailRef = React.createRef();
    this.rePasswordRef = React.createRef();
    this.passwordRef = React.createRef();
    this.adminRef = React.createRef();




  }




  componentDidMount() {


    this.GetAllData(this.state.pageSize, this.state.page);
  }

  GetAllData = (size, page) => {
    const token = getToken();
    let options = {
      headers: {
        Token: token,

      }
    }

    if (token !== null && token !== undefined && token !== "") {
      axios.get(getBaseUrl() + getListUsers() + '?size=' + size + '&page=' + page, options)
        .then(response => {

          if (response.data.code === 200) {

            const data = response.data.results;
            const pagination = response.data.pagination;
            data.forEach(ele => {
              ele.date = new Date(ele.date).toLocaleString();
              ele.dateModified = new Date(ele.dateModified).toLocaleString();
            });

            this.setState({
              rows: data,
              pagination: pagination
            }
            );

          }

        }).catch(error => {


          this.setState(
            {
              textErrorApi: true,
              status: false
            });
        });

    }
  }





  formFilter = (e) => {
    e.preventDefault();
    const size = this.state.pageSize;
    const page = this.state.page;
    this.searchingData(size, page);
  }

  searchingData = (size, page) => {

    let filter = {}

    if (this.state.dateBegin) {
      filter = {
        ...filter,
        dateBegin: this.state.dateBegin.toISOString().slice(0, 10),
      }
    }

    if (this.state.dateEnd) {
      filter = {
        ...filter,
        dateEnd: this.state.dateEnd.toISOString().slice(0, 10),
      }
    }

    if (this.state.email) {
      filter = {
        ...filter,
        email: this.state.email ? this.state.email.target.value : this.state.email,

      }
    }

    if (this.state.tokenAcces) {
      filter = {
        ...filter,
        tokenAccess: this.state.tokenAcces ? this.state.tokenAcces.target.value : this.state.tokenAcces,

      }
    }


    const token = getToken();

    let options = {
      headers: {
        Token: token,

      }
    }

    if (token !== null && token !== undefined && token !== "") {
      axios.post(getBaseUrl() + getUserFilter() + '?size=' + size + '&page=' + page, filter, options)
        .then(response => {
          if (response.data.code === 200) {
            const data = response.data.results;
            const pagination = response.data.pagination;
            data.forEach(ele => {
              ele.date = new Date(ele.date).toLocaleString();
              ele.dateModified = new Date(ele.dateModified).toLocaleString();
            });


            this.setState({
              rows: data,
              pagination: pagination,
              useFilter: true,
            });


          }

          this.setState(
            {
              textErrorApi: false,
              status: true

            });

        }).catch(error => {

          localStorage.setItem('errorMessage', error.response.data.message);
          localStorage.setItem('errorLogin', JSON.stringify(error.response.data.errors));

          this.setState(
            {

              textErrorApi: true,
              status: false

            });
        });
    }
  };



  formCreate = (e) => {
    e.preventDefault();

    let data = {}

    if (this.emailRef) {
      data = {
        ...data,
        email: this.emailRef.value,

      }
    }

    if (this.passwordRef) {
      data = {
        ...data,
        password: this.passwordRef.value,

      }
    }


    if (this.adminRef) {
      data = {
        ...data,
        isAdmin: this.adminRef.checked,

      }
    }


    const token = getToken();

    let options = {
      headers: {
        Token: token,

      }
    }

    if (token !== null && token !== undefined && token !== "") {
      axios.put(getBaseUrl() + getCreateUser(), data, options)
        .then(response => {
          if (response.data.code === 200) {

            this.handleClickSnackBar();


          }

          if (response.data.code === 201) {

            this.handleClickSnackBar();
            this.handleCloseDialog();
            this.GetAllData(this.state.pageSize, this.state.page);

          }

          this.setState(
            {
              textErrorApi: false,
              status: true,
              textSuccesCreated: response.data.message,

            });

        }).catch(error => {

          //localStorage.setItem('errorMessage', error.response.data.message);
          //localStorage.setItem('errorLogin', JSON.stringify(error.response.data.errors));

          this.setState(
            {

              textErrorApi: true,
              status: false,
              textErrorCreated: error.response.data.message,
              textListErrorCreated: JSON.stringify(error.response.data.errors)

            });
        });
    }
  };






  resetForm = () => {
    this.setState(
      {
        status: false,
        statusToken: false,
        statusCredentials: false,
        textErrorApi: false,
        useFilter: false,
        dateBegin: null,
        dateEnd: null,
        email: null,
        tokenAcces: null
      });

    this.GetAllData(this.state.pageSize, 0);

  }


  validateCallUrl = (size, page) => {
    if (this.state.useFilter) {
      this.searchingData(size, page);
    }
    else {
      this.GetAllData(size, page);
    }
  }



  setPageSize = (size) => {
    this.setState({
      pageSize: size
    });
    this.validateCallUrl(size, this.state.page);

  }

  setPage = (page) => {
    this.setState({
      page: page
    }
    )
    this.validateCallUrl(this.state.pageSize, page);
  }

  handleClickOpenDialog = () => {
    this.setState({
      openDialog: true

    })
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false

    })
  };

  validatePassword = () => {

    if (this.passwordRef && this.rePasswordRef) {

      if (this.passwordRef.value !== this.rePasswordRef.value) {

        this.setState({
          notMatchPaswword: true
        })

      }
      else {
        this.setState({
          notMatchPaswword: false
        });
      }
    }

  }

  handleClickSnackBar = () => {
    this.setState({
      openSnackBar: true
    });

  };

  handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      openSnackBar: false
    })
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });

  }
  handleClickShowRePassword = () => {
    this.setState({ showRePassword: !this.state.showRePassword });

  }
  componentWillUnmount() {
    //cuando se destruye el componente se verifica la vigencia del token
    this.props.checkToken();
};


  render() {

    return (
      <React.Fragment>
        <Header stateSesion={this.state.sesion} />
        <div className="App-header" >
          <img src={logo} className="App-img" alt="logo" />
          <h3>Cuentas</h3>
          <Card >
            <CardContent>
              <form onSubmit={this.formFilter}>
                <Box>
                  <Grid container spacing={1} >
                    <Grid container item xs={12} sm={12} md={12}>

                      {
                        this.state.textErrorApi ? (
                          <React.Fragment>
                            <code className="color-red">  {getErrorlist()} </code>
                          </React.Fragment>
                        ) : (
                          <FormLabel id="demo-error-radios">Seccione los filtros necesarios...</FormLabel>
                        )
                      }
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}  >
                      <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                        {/* <InputLabel id="host-select-label">Host</InputLabel> */}
                        <TextField

                          id="email-select"
                          //value={this.state.email?this.state.email.current.value:''}
                          label="email"
                          //onChange={handleChange}
                          onChange={(newValue) => {
                            this.setState({ email: newValue });
                          }}
                        />

                        <FormHelperText >email</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                      <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                        {
                          this.state.agents &&
                          <React.Fragment>
                            {/* <InputLabel id="agent-select-label">User Agents</InputLabel> */}
                            <TextField

                              id="tokenAcces-select"
                              //value={this.state.tokenAcces}
                              label="Token de Acceso"
                              //onChange={handleChange}
                              onChange={(newValue) => {

                                this.setState({ tokenAcces: newValue });
                              }}
                            />

                          </React.Fragment>
                        }
                        <FormHelperText >Aplicaciones generadoras</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} >
                      <FormControl sx={{ m: 1 }} variant="standard" fullWidth>

                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                          <DatePicker
                            label="Fecha de Inicio"
                            value={this.state.dateBegin}
                            onChange={(newValue) => {
                              this.setState({ dateBegin: newValue });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} >
                      <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Fecha Final"
                            value={this.state.dateEnd}
                            onChange={(newValue) => {
                              this.setState({
                                dateEnd: newValue
                              })
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={6} md={1}>
                      <FormControl sx={{ m: 1 }} fullWidth>
                        <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                          Filtrar
                        </Button>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1}>
                      <FormControl sx={{ m: 1 }} fullWidth>
                        <Button sx={{ mt: 1, mr: 1 }} type="button" onClick={this.resetForm} variant="outlined">
                          Deshacer
                        </Button>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={1}>
                      <FormControl sx={{ m: 1 }} fullWidth>
                        <Button sx={{ mt: 1, mr: 1 }} type="button" onClick={this.handleClickOpenDialog} variant="outlined">
                          Agregar
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </CardContent>
          </Card>
          <Box width="100%"  >
            <Grid   >

              <Grid item md={12} sm={12}>
                <Card >
                  <CardContent style={{ height: 640, width: '100%' }}>
                    <DataGrid
                      rows={this.state.rows}
                      columns={columns}
                      getRowId={(row) => row._id}

                      page={this.state.page}
                      paginationMode="server"

                      onPageChange={(newPage) => this.setPage(newPage)}
                      pageSize={this.state.pageSize}
                      onPageSizeChange={(newPage) => this.setPageSize(newPage)}

                      rowsPerPageOptions={[10, 25, 50, 100]}
                      pagination

                      rowCount={this.state.pagination.total}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </div>

        <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog}>
          <form onSubmit={this.formCreate}>
            <DialogTitle>Crear un nuevo Usuario</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Al crear un nuevo usuario automaticamente se le genera un token de Acceso.

                <br />
                {
                  this.state.textErrorCreated ? (
                    <React.Fragment>

                      <Alert severity="warning">{this.state.textErrorCreated}</Alert>

                      <Alert severity="error"> {this.state.textListErrorCreated} </Alert>


                    </React.Fragment>
                  ) : (
                    <FormLabel id="demo-error-radios">Por favor llene los siguientes campos</FormLabel>
                  )
                }
              </DialogContentText>

              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Correo electronico"
                type="email"
                fullWidth
                variant="standard"
                inputRef={element => (this.emailRef = element)}
                required
              />
              <FormControl fullWidth sx={{ m: 0 }} variant="standard">

                <InputLabel htmlFor="newPassword">Contraseña</InputLabel>
                <Input
                  autoFocus
                  margin="dense"
                  id="newPassword"
                  label="Contraseña Nueva"
                  fullWidth
                  variant="standard"
                  inputRef={element => (this.passwordRef = element)}
                  type={this.state.showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 0 }} variant="standard">
                <InputLabel htmlFor="repPassword">Repitir Contraseña</InputLabel>
                <Input
                  autoFocus
                  margin="dense"
                  id="repPassword"
                  label="Repetir Contraseña"
                  fullWidth
                  variant="standard"
                  inputRef={element => (this.rePasswordRef = element)}
                  onChange={this.validatePassword}
                  type={this.state.showRePassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowRePassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showRePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                />
              </FormControl>
              {
                this.state.notMatchPaswword &&
                <FormHelperText className="color-red">La contraseña no coicide, por favor verifique</FormHelperText>
              }

              <FormGroup>
                <FormControlLabel control={<Switch defaultNotChecked
                  inputRef={element => (this.adminRef = element)}
                />} label="Es usuario administrador" />
              </FormGroup>

            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDialog}>Cancelar</Button>
              <Button type="submit">Crear</Button>
            </DialogActions>
          </form>
        </Dialog>

        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar open={this.state.openSnackBar} autoHideDuration={6000} onClose={this.handleCloseSnackBar}>
            <Alert onClose={this.handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
              {this.state.textSuccesCreated}
            </Alert>
          </Snackbar>
        </Stack>

      </React.Fragment>
    )
  }
}

export default Cuentas;