import React, { Component } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import IsAuthenticated, { saveToken } from '../../../service/AuthService';

import { getLoginToken, getLoginCreedencials, getBaseUrl } from '../../../service/apiParameters';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import axios from 'axios';
import { Navigate } from 'react-router-dom';

import Header from "../../header/header";
import logo from '../../../assets/images/celtech-title.png';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

class Login extends Component {

    constructor(props) {
        super(props);
        this.typeTokenRef = React.createRef();
        this.typeCredRef = React.createRef();
        this.tokenRef = React.createRef();
        this.userRef = React.createRef();
        this.passwordRef = React.createRef();

        this.state = {
            status: false,
            statusToken: false,
            statusCredentials: false,
            textErrorApi: false,
            showPassword: false,
            getErrorMessage: null,
            getListError: null,
            messageActiveSession: null,
            sendReq:false
        };
    }

    validateToken(user) {

        axios.post(getBaseUrl() + getLoginToken(), user)
            .then(response => {
                if (response.data.code === 200) {
                    saveToken(response.data.results.token);
                   
                    this.setState(
                        {
                            textErrorApi: false,
                            sendReq:false,
                            status:true
                        });
                    // manda la señal al enrutador para iniciar autorizacion
                    this.props.LoginStatus();
                }
                

            }).catch(error => {

                this.setState(
                    {
                        textErrorApi: true,
                        sendReq:false,
                        status:false,
                        getErrorMessage: error.response.data.message,
                        getListError: JSON.stringify(error.response.data.errors, null, 4),
                    });
            });
    };


    validate(user) {
        axios.post(getBaseUrl() + getLoginCreedencials(), user)
            .then(response => {
                if (response.data.code === 200) {
                    saveToken(response.data.results.token);

                    this.setState(
                        {
                            textErrorApi: false,
                            sendReq:false,
                            status:true
                        });  
                    // manda la señal al enrutador para iniciar autorizacion
                    this.props.LoginStatus();
                }
                

            }).catch(error => {

                this.setState(
                    {
                        textErrorApi: true,
                        sendReq:false,
                        status:false,
                        getErrorMessage: error.response.data.message,
                        getListError: JSON.stringify(error.response.data.errors, null, 4),
                    });
            });
    }

    handleChangeToken = (event) => {

        this.setState(
            {
                statusToken: event.target.checked,
                statusCredentials: false,

            }
        );
    }


    handleChangeCreed = (event) => {
        this.setState(
            {
                statusCredentials: event.target.checked,
                statusToken: false,
            }
        );
    }

    formLogin = (e) => {
        e.preventDefault();

        
        this.setState({
            sendReq:true
        })


        let user = {};

        if (this.typeTokenRef.checked) {
            let user = {
                //token_type: this.typeTokenRef.checked,
                //cred_type: this.typeCredRef.checked,
                token: this.tokenRef.value
            };

            this.validateToken(user);
            
            this.setState({
                user: user,
            }
            );
        }
        else if (this.typeCredRef.checked) {
            user = {

                email: this.userRef.value,
                password: this.passwordRef.value
            }
            this.validate(user);
            this.setState(
                {
                    users: user
                }
            );
            

            
        }

        //tiempo de espera

        


    }

  

    resetForm = () => {
        this.setState(
            {
                status: false,
                statusToken: false,
                statusCredentials: false,
                textErrorApi: false,

            });
    }

    componentWillMount() {
        this.checkSession();
    }

    checkSession = () => {

        if (IsAuthenticated()) {
            this.setState({
                messageActiveSession: true,
            });
        }
        else {
            this.setState({
                messageActiveSession: false,
            });
        }
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });

    }


    chandleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    goHome = () => {

        this.setState({ status: true });

    }
   

    

    handleClose=()=>{
        this.setState({ sendReq: false });
    }

    render() {

        return (
            <React.Fragment>
                <Header />
                <div className="App-header" >
                    <img src={logo} className="App-img" alt="logo" />
                    <div>
                        {
                            this.state.status &&
                                <Navigate to="/" replace />
                        }

                        <h1>Inicio de Sesion</h1>
                        <form onSubmit={this.formLogin}>
                            <Box my={2}>
                                <Card>
                                    <CardContent>
                                        <Grid >
                                            <Grid item>
                                                {
                                                    this.state.messageActiveSession &&
                                                    <React.Fragment>
                                                        <Stack sx={{ width: '100%' }} spacing={2}>

                                                            <Alert variant="outlined" severity="success"
                                                                action={
                                                                    <Button color="inherit" size="small" onClick={this.goHome}>
                                                                        continuar
                                                                    </Button>
                                                                }>Existe una session activa. Desea continuar?</Alert>
                                                        </Stack>

                                                    </React.Fragment>
                                                }

                                                {
                                                    this.state.textErrorApi &&
                                                    <React.Fragment>
                                                        <code className="color-red">  {this.state.getListError} </code>
                                                    </React.Fragment>
                                                }
                                            </Grid>
                                            <Grid item>
                                                <FormControl sx={{ m: 3 }} variant="standard" >
                                                    <FormLabel id="demo-error-radios">Seccione el metodo para iniciar sesion...</FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="demo-error-radios"
                                                        name="type-login"
                                                    >
                                                        <FormControlLabel value="token" control={<Radio
                                                            inputRef={element => (this.typeTokenRef = element)}
                                                            onChange={this.handleChangeToken}
                                                            required
                                                        />} label="Inicie sesion usando un token de acceso" />
                                                        <FormControlLabel value="config" control={<Radio
                                                            inputRef={element => (this.typeCredRef = element)}
                                                            onChange={this.handleChangeCreed}
                                                            required
                                                        />} label="Inicie sesion usando creedenciales" />
                                                    </RadioGroup>
                                                    <FormHelperText >* Metodos para iniciar sesion</FormHelperText>
                                                </FormControl>
                                            </Grid>


                                            <Grid item>
                                                {this.state.statusToken === true &&
                                                    <React.Fragment>

                                                        <FormControl fullWidth sx={{ m: 0 }} variant="standard">
                                                            <FormLabel id="token">Ingrese su token</FormLabel>
                                                            <TextField

                                                                id="token" label="Token SHA512" name="token"
                                                                inputRef={element => (this.tokenRef = element)}

                                                                variant="standard"
                                                                required />
                                                            {
                                                                this.state.textErrorApi &&
                                                                <FormHelperText error >{this.state.getErrorMessage}</FormHelperText>
                                                            }

                                                        </FormControl>
                                                    </React.Fragment>

                                                }


                                                {this.state.statusCredentials === true &&
                                                    <React.Fragment>

                                                        <FormControl fullWidth sx={{ m: 0 }} variant="standard" >
                                                            <FormLabel id="usuario">Ingrese su correo electrónico</FormLabel>
                                                            <TextField

                                                                id="usuario" label="Correo electrónico"
                                                                inputRef={element => (this.userRef = element)}

                                                                variant="standard"
                                                                required />
                                                        </FormControl>
                                                        <FormControl fullWidth sx={{ m: 0 }} variant="standard">
                                                            <FormLabel id="password">Ingrese su Clave</FormLabel>
                                                            <InputLabel htmlFor="password">Contraseña</InputLabel>
                                                            <Input fullWidth

                                                                id="password" label="Contraseña"
                                                                inputRef={element => (this.passwordRef = element)}
                                                                type={this.state.showPassword ? 'text' : 'password'}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={this.handleClickShowPassword}
                                                                            onMouseDown={this.handleMouseDownPassword}
                                                                        >
                                                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                                variant="standard"
                                                                required />
                                                        </FormControl>
                                                    </React.Fragment>

                                                }
                                            </Grid>
                                            <Grid item>
                                                <FormControl>
                                                    <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                                        Iniciar Sesion
                                                    </Button>
                                                    <Button sx={{ mt: 1, mr: 1 }} type="button" onClick={this.resetForm} variant="outlined">
                                                        Limpiar
                                                    </Button>

                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                    </CardContent>
                                </Card>

                            </Box>
                            <Container >
                            </Container>
                        </form>
                    </div>
                    
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.sendReq}
                    onClick={this.handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                            
            </React.Fragment>
        )
    };
}

export default Login;