
import React from 'react';
import './assets/css/App.css';

import { StyledEngineProvider } from '@mui/material/styles';
import ReactDOM from 'react-dom/client';
import Router from './components/Router';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return (
    ReactDOM.createRoot(document.querySelector("#root")).render(
      <React.StrictMode>
        <StyledEngineProvider injectFirst>
         
          <div className="App" >
            <Router></Router>
          </div>
        </StyledEngineProvider>
      </React.StrictMode>
    )





    
  );
}

export default App;
