import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import Header from "../../header/header";
import logo from '../../../assets/images/celtech-title.png';

import { IsExpires } from "../../../service/AuthService";
import SessionExp from '../../header/sessionExpired';
import Alert from '@mui/material/Alert';

class RequireLogin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isExpires: false
        }

    }


    componentDidMount() {

        if (IsExpires()) {

            this.setState({
                isExpires: true
            });
        }

        else {
            this.setState({
                isExpires: false
            });
        }

    }


    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="App-header" >
                    <img src={logo} className="App-img" alt="logo" />

                    
                    {
                        this.state.isExpires ?
                            (
                                <React.Fragment>
                                    <h2>Lo sentimos</h2>
                                    <Alert severity="error">Su session ha expirado, por favor inicie sesion nuevamente</Alert>
                                </React.Fragment>
                            ) :
                            (
                                <React.Fragment>
                                    <h2>Autorizacion Requerida</h2>
                                <Alert severity="info">Por favor, inicie sesion ver el contenido de la aplicacion</Alert>
                                </React.Fragment>
                                
                            )

                    }

                    <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                        <NavLink className="color-bluesky" to='/auth/login' >
                            Ir a Inicio de Sesion
                        </NavLink>
                    </Button>

                </div>

                {
                    this.state.isExpires &&
                    <SessionExp />
                }
            </React.Fragment>
        )
    }
}

export default RequireLogin;