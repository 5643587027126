import React, { Component } from 'react';

import IsAuthenticated from '../../service/AuthService';
import ResponsiveAppBar from './navBarLogin'
import NavbarCustom from './navbarCustom'
import SessionExp from './sessionExpired';
class Header extends Component {
    state = {
        status: false,
        sesion: false,
    }

    componentWillMount() {
        const stateSesion = this.props.stateSesion;
        this.setState({
            sesion: stateSesion
        })  
        
        
        if (IsAuthenticated()) {

            this.setState({
                status: true
            });
        }
        else {

            this.setState({
                status: false
            });
        }


    };


    render() {
        return (
            <React.Fragment>
                {
                    this.state.status ?
                        (<ResponsiveAppBar />)
                        :
                        (<NavbarCustom />)
                }

                {/* verificar session */}

                {
                    this.state.sesion && 
                    <SessionExp />

                }   
                
            </React.Fragment>



        )
    }

}

export default Header;