
import { isAdmin,userIdSession } from "./jwt";
import {getToken } from './AuthService'

function HeaderInyected(){

    const token = getToken();
    const userId = userIdSession();

    let options;
    if(!isAdmin() && userId){

        options = {
            headers: {
                Token: token,
                userId:userId,
            }
        }

        return options;
    }

     options = {
        headers: {
            Token: token,
        }
    }

    return options;
}

export default HeaderInyected;