import { Navigate } from "react-router-dom";

//redirecciona a pagina not Access, cuando no se tiene privilegios 
const ProtectedRoute = ({ isAdmin, children }) => {
  if (!isAdmin) {
    return <Navigate to="/auth/protected" replace />;
  }

  return children;
};


//redirecciona a una pagina que indica que es necesario iniciar sesion
//tambien cuando el token ha expirado
export const RequireAuth = ({ isAuth, children }) => {
  
  if (!isAuth) {
    return <Navigate to="/auth/require" replace />;
  }

  return children;
};


export default ProtectedRoute;