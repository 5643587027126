import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import axios from 'axios';

import { getBaseUrl, getAllUrlGenerate } from '../../service/apiParameters';
import HeaderInyected from "../../service/headers";


function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { idUrl } = useParams();
 

  return (
    { idUrl }
  );
}

const columns = [

  {
    field: 'urlId',
    headerName: ' ID URL',
    description: 'Codigo Unico para acorta la Url',
    sortable: true,
    width: 210,
    valueGetter: (params) =>
      `${params.row.urlId || ''}`,
  },

  { field: 'origUrl', headerName: 'Original Url', width: 400 },
  { field: 'shortUrl', headerName: 'Url Corto', width: 330 },
  { field: 'host', headerName: 'Host', width: 150 },

  { field: 'userAgent', headerName: 'User Agent', width: 200 },

  { field: 'clicks', headerName: 'Clicks', type: 'number', width: 70, },
  { field: 'date', headerName: 'Fecha y Hora', width: 200 },

];

class UrlTable extends React.Component {

  constructor() {
    super();
    this.state = {
      rows: [],
      pagination: {},
      pageSize: 5,
      page: 0
    }
  }

  componentWillMount() {
    this.GetAllData(this.state.pageSize, this.state.page);
  }

  GetAllData = (size, page) => {
   
      axios.get(getBaseUrl() + getAllUrlGenerate() + '?size=' + size + '&page=' + page, HeaderInyected())
        .then(response => {
         
          if (response.data.code === 200) {

            response.data.results.forEach(ele => {
              ele.date = new Date(ele.date).toLocaleString(); // '2013-11-18T11:55:20-06:00'
            });

            const data = response.data.results;
            const pagination = response.data.pagination;

            this.setState({
              rows: data,
              pagination: pagination,  
            }
            );
  
          }

        }).catch(error => {
          //localStorage.setItem('errorMessage', error.response.data.message);
          //localStorage.setItem('errorLogin', JSON.stringify(error.response.data.errors));

          this.setState(
              {
                  textErrorApi:true,
                  status:false  
              });
        });
  }


  setPageSize = (size) => {
    
    this.setState({
      pageSize: size
    }
    )
    this.GetAllData(size, this.state.page);
  }

  setPage = (page) => {
  
    this.setState({
      page: page
    }
    )
    this.GetAllData(this.state.pageSize, page);
  }


  render() {
    return (
      <React.Fragment>
        <h3>Detalle de URL Generadas: {Child}  </h3>

        <Box style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={this.state.rows}
            columns={columns}
            getRowId={(row) => row._id}

            page={this.state.page}
            paginationMode="server"

            onPageChange={(newPage) => this.setPage(newPage)}
            pageSize={this.state.pageSize}
            onPageSizeChange={(newPage) => this.setPageSize(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            rowCount={this.state.pagination.total}
          />
        </Box>
      </React.Fragment>

    )
  }

}

export default UrlTable;