import React from "react";

import Header from "../header/header";
import logo from '../../assets/images/celtech-title.png';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';

class NotFound extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="App-header" >
                    <img src={logo} className="App-img" alt="logo" />

                    <h1>Error 404 _ Not Found</h1>
                    <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined" >
                        <NavLink to='/auth/login' className="color-white">Iniciar Sesion</NavLink>
                    </Button>
                </div>
            </React.Fragment>
        )
    }
}

export default NotFound;