
import React from "react";

import Top10URL from '../url/top10_url';
import IsAuthenticated from '../../service/AuthService';
import { Box, Card, CardContent, Container, Grid } from '@mui/material';
import GenerateUrl from "../url/gerateUrl";
import Agents from "../url/agents";
import Hosts from "../url/host";
import UrlTable from "../url/urlTable";

import Header from "../header/header";
import logo from '../../assets/images/celtech-title.png';

class Home extends React.Component {

    state = {
        url: [],
        status: false
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (IsAuthenticated()) {
           
    //         this.setState({
    //             status: false
    //         });
    //     }
    //     else {
    //         this.setState({
    //             status: true
    //         });
    //     }
      
    // }

    componentDidMount() {

        if (IsAuthenticated()) {
           
            this.setState({
                status: false
            });
        }
        else {
            this.setState({
                status: true
            });
        }
    };


    componentWillUnmount() {
        this.props.checkToken();
    };

    ChangeDataTop10(){

    }

    render() {

        return (
            <React.Fragment>
                <Header />
                <div className="App-header" >
                    <img src={logo} className="App-img" alt="logo" />
                
                    
                            <React.Fragment>
                                <h1>Shortner Dashboard</h1>
                                <Box width="100%"  >
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} >
                                            <Container maxWidth="lg" >
                                                <Card height="60px">
                                                    <CardContent>
                                                        <GenerateUrl />
                                                    </CardContent>
                                                </Card>
                                            </Container>

                                        </Grid>
                                        <Grid item xs={12} md={4} sm={12}>
                                            <Card>
                                                <CardContent>
                                                    <Top10URL data={this.ChangeDataTop10}/>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} md={4} sm={12}>
                                            <Card>
                                                <CardContent>
                                                    <Agents />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={4} sm={12} >
                                            <Card>
                                                <CardContent>
                                                    <Hosts />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Container my={4} sx={{ m: 2 }} maxWidth="lx" >
                                    <Card>
                                        <CardContent>
                                            <UrlTable />
                                        </CardContent>
                                    </Card>
                                </Container>
                            </React.Fragment>
                     
                </div>
            </React.Fragment>
        )
    }
}

export default Home;