import React, { Component } from 'react';
import Header from "../header/header";
import logo from '../../assets/images/celtech-title.png';
import { Box, Grid, Card, CardContent, CardHeader } from '@mui/material';
import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import axios from 'axios';
import { getBaseUrl, getOneUser, getChageToken, getChangePassword } from '../../service/apiParameters';

import { getErrorlist,  getToken, } from '../../service/AuthService';

import decodeToken from '../../service/jwt'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MuiAlert from '@mui/material/Alert';
import FormLabel from '@mui/material/FormLabel';

import FormGroup from '@mui/material/FormGroup';

import FormControl from '@mui/material/FormControl';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Collapse from '@mui/material/Collapse';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});




const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

class Perfil extends Component {

    constructor() {
        super();
        this.state = {
            expanded: false,
            user: {},
            idUser: '',
            openChangePW: false,
            openChangeToken: false,
            openSnackBar: false,
            textSuccesCreated: null,
            textErrorCreated: null,
            textErrorApi: false,
            showCurrentPassword: false,
            showPassword: false,
            showRePassword: false,


        }

        this.currentPassword = React.createRef();
        this.passwordRef = React.createRef();
        this.rePasswordRef = React.createRef();
        this.changeTokenRef = React.createRef();
    }

    //  fullScreen = useMediaQuery(styled.breakpoints.down('md'));

    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded });
    };


    componentDidMount() {

        const jwt = decodeToken();
        if (jwt.id ) {

            this.searchPerfil(jwt.id);

            this.setState({
                idUser: jwt.id
            })
        }




    }

    searchPerfil(idUser) {

        const token = getToken();

        let options = {
            headers: {
                Token: token,

            }
        }
        if (token !== null && token !== undefined && token !== "") {
            axios.get(getBaseUrl() + getOneUser() + "/" + idUser, options)
                .then(response => {

                    if (response.data.code === 200) {

                        if(response.data.results){
                            response.data.results.date = new Date(response.data.results.date).toLocaleString();
                            response.data.results.dateModified = new Date(response.data.results.dateModified).toLocaleString();
                        }

                        const data = response.data.results;



                        this.setState({
                            user: data,
                        })

                    }

                })
                .catch(error => {
                    
                    localStorage.setItem('errorMessage', error.response.data.message);
                    localStorage.setItem('errorLogin', JSON.stringify(error.response.data.errors));
                    this.setState({
                        textErrorApi: true,
                    });
                });
        }



    }



    formChangePassword = (e) => {
        e.preventDefault();

        let data = {}


        if (this.state.idUser) {
            data = {
                ...data,
                idUser: this.state.idUser,
            }
        }

        if (this.currentPassword) {
            data = {
                ...data,
                currentPassword: this.currentPassword.value,

            }
        }

        if (this.currentPassword) {
            data = {
                ...data,
                currentPassword: this.currentPassword.value,

            }
        }

        if (this.passwordRef) {
            data = {
                ...data,
                newPassword: this.passwordRef.value,

            }
        }



        const token = getToken();

        let options = {
            headers: {
                Token: token,

            }
        }

        if (token !== null && token !== undefined && token !== "") {
            axios.post(getBaseUrl() + getChangePassword(), data, options)
                .then(response => {
                    if (response.data.code === 200) {
                        
                        this.handleClickSnackBar();
                        this.handleClosePW();


                    }

                    this.setState(
                        {
                            textErrorApi: false,
                            status: true,
                            textSuccesCreated: response.data.message,
                            textErrorCreated: null,
                            textListErrorCreated: null

                        });

                }).catch(error => {

                    //localStorage.setItem('errorMessage', error.response.data.message);
                    //localStorage.setItem('errorLogin', JSON.stringify(error.response.data.errors));

                    this.setState(
                        {

                            textErrorApi: true,
                            status: false,
                            textErrorCreated: error.response.data.message,
                            textListErrorCreated: JSON.stringify(error.response.data.errors)

                        });
                });
        }
    };


    formChangeToken = (e) => {
        e.preventDefault();
        let data = {}

        if (this.changeTokenRef) {
            data = {
                ...data,
                agree: this.changeTokenRef.checked,

            }
        }

        if (this.state.idUser) {
            data = {
                ...data,
                idUser: this.state.idUser
            }
        }

        if (this.state.user) {
            data = {
                ...data,
                tokenAcces: this.state.user.tokenAccess,

            }
        }


        const token = getToken();

        let options = {
            headers: {
                Token: token,

            }
        }

        if (token !== null && token !== undefined && token !== "" && data.agree === true) {
            axios.post(getBaseUrl() + getChageToken(), data, options)
                .then(response => {
                    if (response.data.code === 200) {
                        //const data = response.data.results;
                        this.handleClickSnackBar();
                        this.handleCloseToken();
                        this.searchPerfil(this.state.idUser);

                    }

                    this.setState(
                        {
                            textErrorApi: false,
                            status: true,
                            textSuccesCreated: response.data.message,

                        });

                }).catch(error => {

                    this.setState(
                        {

                            textErrorApi: true,
                            status: false,
                            textErrorCreated: error.response.data.message,
                            textListErrorCreated: JSON.stringify(error.response.data.errors)

                        });
                });
        }
    };

    handleClickOpenPW = () => {
        this.setState({
            openChangePW: true

        })
    };

    handleClosePW = () => {
        this.setState({
            openChangePW: false

        })
    };

    handleClickOpenToken = () => {
        this.setState({
            openChangeToken: true

        })
    };

    handleCloseToken = () => {
        this.setState({
            openChangeToken: false

        })
    };

    handleClickSnackBar = () => {
        this.setState({
            openSnackBar: true
        });

    };

    handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            openSnackBar: false
        })
    };

    validatePassword = () => {

        if (this.passwordRef && this.rePasswordRef) {
            if (this.passwordRef.value !== this.rePasswordRef.value) {

                this.setState({
                    notMatchPaswword: true
                })

            }
            else {
                this.setState({
                    notMatchPaswword: false
                });
            }



        }

    }

    handleClickShowCurrentPassword = () => {
        this.setState({ showCurrentPassword: !this.state.showCurrentPassword });

    }
    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });

    }
    handleClickShowRePassword = () => {
        this.setState({ showRePassword: !this.state.showRePassword });

    }
    componentWillUnmount() {
       //cuando se destruye el componente se verifica la vigencia del token
        this.props.checkToken();
    };
    render() {
        return (
            <React.Fragment>
                <Header stateSesion={this.state.sesion}/>
                <div className="App-header" >
                    <img src={logo} className="App-img" alt="logo" />


                    <h3>Mi Perfil</h3>

                    <Card >
                        {
                            this.state.textErrorApi &&
                            <React.Fragment>
                                <code className="color-red">  {getErrorlist()} </code>
                            </React.Fragment>

                        }


                        {

                            this.state.user &&
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                        P
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="Ver Token" onClick={this.handleExpandClick}>
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title={this.state.user.email}
                                subheader={this.state.user.date} />

                        }

                        <CardContent>
                            <Box >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            Email:
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary' }}>{this.state.user.email}</Typography>
                                    </Grid>


                                    <Grid item xs={12}>
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            Fecha Creacion:
                                        </Typography>

                                        <Typography sx={{ color: 'text.secondary' }}>{this.state.user.date}</Typography>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            Fecha Ultima Modificacion:
                                        </Typography>

                                        <Typography sx={{ color: 'text.secondary' }}>{this.state.user.dateModified}</Typography>
                                    </Grid>
                                </Grid>

                            </Box>








                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={this.handleClickOpenPW}>Cambiar Contraseña</Button>



                            <Dialog open={this.state.openChangePW} onClose={this.handleClosePW}>
                                <DialogTitle>Cambiar contraseña</DialogTitle>
                                <form onSubmit={this.formChangePassword}>
                                    <DialogContent>
                                        <DialogContentText>
                                            Por favor indique las nuevas claves para usar con esta cuenta.

                                            <br />
                                            {
                                                this.state.textErrorCreated ? (
                                                    <React.Fragment>

                                                        <Alert severity="warning">{this.state.textErrorCreated}</Alert>

                                                        <Alert severity="error"> {this.state.textListErrorCreated} </Alert>


                                                    </React.Fragment>
                                                ) : (
                                                    <FormLabel id="demo-error-radios">Llene los siguientes campos</FormLabel>
                                                )
                                            }
                                        </DialogContentText>
                                        <FormControl fullWidth sx={{ m: 0 }} variant="standard">
                                            <InputLabel htmlFor="pw-current">Contraseña Actual</InputLabel>


                                            <Input
                                                autoFocus
                                                margin="dense"
                                                id="pw-current"
                                                label="Contraseña Actual"
                                                type={this.state.showCurrentPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowCurrentPassword}
                                                            onMouseDown={this.handleMouseDownPassword}
                                                        >
                                                            {this.state.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                fullWidth
                                                variant="standard"
                                                inputRef={element => (this.currentPassword = element)}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth sx={{ m: 0 }} variant="standard">
                                            <InputLabel htmlFor="newPassword">Nueva Contraseña</InputLabel>

                                            <Input
                                                autoFocus
                                                margin="dense"
                                                id="newPassword"
                                                label="Contraseña Nueva"
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword}
                                                            onMouseDown={this.handleMouseDownPassword}
                                                        >
                                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                fullWidth
                                                variant="standard"
                                                inputRef={element => (this.passwordRef = element)}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth sx={{ m: 0 }} variant="standard">
                                            <InputLabel htmlFor="repPassword">Repitir Contraseña</InputLabel>
                                            <Input
                                                autoFocus
                                                margin="dense"
                                                id="repPassword"
                                                label="Repetir Contraseña"
                                                type={this.state.showRePassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowRePassword}
                                                            onMouseDown={this.handleMouseDownPassword}
                                                        >
                                                            {this.state.showRePassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                fullWidth
                                                variant="standard"
                                                onChange={this.validatePassword}
                                                inputRef={element => (this.rePasswordRef = element)}
                                            />
                                        </FormControl>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleClosePW}>Cancelar</Button>
                                        <Button type="submit">Cambiar</Button>
                                    </DialogActions>

                                </form>
                            </Dialog>


                            <Button size="small" onClick={this.handleClickOpenToken}>Nuevo Token</Button>


                            <Dialog
                                //fullScreen={this.fullScreen}
                                open={this.state.openChangeToken}
                                onClose={this.handleCloseToken}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    {"Acepta las politicas de cambio de Token?"}
                                </DialogTitle>
                                <form onSubmit={this.formChangeToken}>
                                    <DialogContent>
                                        <DialogContentText>

                                            {/* <Typography sx={{ color: 'text.secondary' }}>
                                                <code className="color-red">
                                                    {this.state.user.tokenAccess}
                                                </code></Typography> */}


                                            <Typography variant="body2" color="text.secondary">
                                                Al cambio este Token, el sistema generara un nuevo alertorio
                                                por lo que cualquier lugar o aplicacion de usar este TOKEN dejara
                                                de funcionar y debera cambiarlo por el nuevo.
                                            </Typography>


                                            <Typography variant="body2" color="text.secondary">
                                                * Todo cambio es responsabilidad de este usuario.
                                            </Typography>
                                            <FormGroup>
                                                <FormControlLabel control={<Switch defaultNotChecked
                                                    inputRef={element => (this.changeTokenRef = element)}
                                                />} label="Acepto las condiciones" />
                                            </FormGroup>

                                            {
                                                this.state.textErrorCreated &&
                                                <React.Fragment>

                                                    <Alert severity="warning">{this.state.textErrorCreated}</Alert>

                                                    <Alert severity="error"> {this.state.textListErrorCreated} </Alert>


                                                </React.Fragment>

                                            }

                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button autoFocus onClick={this.handleCloseToken}>
                                            Rechazar
                                        </Button>
                                        <Button type="submit" autoFocus>
                                            Estoy De Acuerdo
                                        </Button>

                                    </DialogActions>

                                </form>
                            </Dialog>
                            <ExpandMore
                                expand={this.state.expanded}
                                onClick={this.handleExpandClick}
                                aria-expanded={this.state.expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </CardActions>


                        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                <Typography paragraph sx={{ flexShrink: 0 }} gutterBottom variant="h6" component="div"> Token De Acceso:</Typography>
                                <Grid item xs={12}>
                                    <Typography >
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        <code className="color-red">
                                            {this.state.user.tokenAccess}
                                        </code></Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        * Favor de compatir este Token no ninguna persona.
                                    </Typography>

                                </Grid>
                            </CardContent>
                        </Collapse>

                    </Card>

                </div>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={this.state.openSnackBar} autoHideDuration={6000} onClose={this.handleCloseSnackBar}>
                        <Alert onClose={this.handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                            {this.state.textSuccesCreated}
                        </Alert>
                    </Snackbar>
                </Stack>
            </React.Fragment >

        )
    }


}

export default Perfil;