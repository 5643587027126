import jwt_decode from "jwt-decode";
import {getToken} from './AuthService';



function decodeToken (){
     
    
    const token = getToken();
    
    

    if (token !== null && token !== undefined && token !== "") {
        
       var decoded = jwt_decode(token) ;

       
        return decoded;
        
    
    }
    
    return null;
}

export function userIdSession()
{
 
    const token = getToken();
    
    

    if (token !== null && token !== undefined && token !== "") {
        var decoded = jwt_decode(token) ;

        if(decoded && decoded.id){
            return decoded.id
        }

        if(decoded && decoded.token){
            return decoded.token
        }
    }
    return null;

}

export function isAdmin()
{
 
    const token = getToken();
    
    

    if (token !== null && token !== undefined && token !== "") {
        var decoded = jwt_decode(token) ;

        if(decoded && decoded.admin){
            return decoded.admin
        }
        else if(decoded && decoded.token){
            return decoded.token
        }
    }
    return false;

}


export default decodeToken;