import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import LinkIcon from '@mui/icons-material/Link';
import logo from '../../assets/images/celtechLogo.png';
import { NavLink } from 'react-router-dom';
import IsAuthenticated,{DisableSession} from '../../service/AuthService';
const pages = 
[
    {
        nombre:'Inicio',
        url: "/",
    },
    {
        nombre:'Top 10',
        url: "/top",
    },
    {
        nombre:'Ver Links',
        url: "/short",
    }
];

const settings = [
  {
    nombre: 'Perfil',
    action:'/perfil'
  },
  {
    nombre:'Cuenta',
    action:'/cuenta',
  },
  {
    nombre:'Cerrar Sesion',
    action:'/auth/out'
  } ];


  

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);


  };

  const handleCloseUserMenu = () => {
    
    setAnchorElUser(null);
  };

 const closeSesion=(e,key) => {
    
    if(key==='/auth/out'){
      if(IsAuthenticated()){
        DisableSession();
      }
      
    }

    setAnchorElUser(null);
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={logo} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}  className="App-logo" alt="logo"  />
          <LinkIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Shortner-Dashboard
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (

                // <Link href={page.url}>{page.nombre}</Link>

                
                <NavLink 
                    className='color-bluesky'
                    activeClassName='active'
                    key={page.nombre} 
                    to={page.url}
                    //containerElement={<Link to="/profile" />}
                    //component={Link}
                    //to="/profile"
                    onClick={handleCloseNavMenu}>
                    <MenuItem> <Typography textAlign="center">{page.nombre}</Typography>
                    </MenuItem>
                  
                </NavLink>
                
              ))}
            </Menu>
          </Box>
          <LinkIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          {/* <img src={logo} className="App-logo" alt="logo" sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}  /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 1,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.2rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Shortner-Dashboard
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <MenuItem>
              <NavLink
              //containerElement={<Link to="/profile" />}
              //component={Link}
              //to="/profile"
                className='Nav-link'
             
                key={page.nombre}
                to={page.url}

                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.nombre}
              </NavLink>
              </MenuItem>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <NavLink className="color-bluesky" key={setting.nombre} id={setting.nombre}  to={setting.action} onClick={ (e) =>  closeSesion(e,setting.action)  }>
                  <MenuItem>
                    <Typography textAlign="center">{setting.nombre}</Typography>
                  </MenuItem>
                </NavLink>
                
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
