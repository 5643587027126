import React from "react";
import Home from "../components/home/home";
import ShortUrlTable from "./short/shortUrlTable";
import NotFound from "./404/not_found";
import IsAuthenticated from "../service/AuthService";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Url from "./url/urlTable";
import Login from "./auth/login/login";
import Out from "./auth/logout/out";
import TopURL from "./top/topUrl";
import Cuentas from "./usuarios/cuenta";
import Perfil from "./usuarios/perfil";
import ProtectedRoute, { RequireAuth } from "../service/ProtectedRoute";
import { isAdmin } from "../service/jwt";
import NotAccess from "./auth/not_access/protected";
import RequireLogin from "./auth/not_access/requireAuth";

class Router extends React.Component {

    // statusSesion = false;

    // status = {
    //     sesion: false,
    //     isAdmin: null,
    //     isAuth: null,
    // }

    constructor(){
        super();
        this.state ={
            isAdmin: null,
            isAuth: null,
        }
        
    }


    componentWillMount() {
       this.CheckToken();
       
    };
    //verificar si hay una sesion iniciada
    CheckToken=()=>{
        
        
        //verificar si es un usuario con 
        //privilegios de administrador
        if (isAdmin()) {
            this.setState({
                isAdmin: true
            })
        }
        else {
            this.setState({
                isAdmin: false
            })
        }
        // si hay una sesion activa
        if (IsAuthenticated()) {
            this.setState({
                isAuth: true
            })
        }
        else {
            this.setState({
                isAuth: false
            })
        }


    }
    // metodo para iniciar chequeo, despues de obtener autorizacion del TOKEN JWT en API
    LoginStatus=()=>{
        this.CheckToken();
       
    }

    render() {


        return (

            <BrowserRouter>
                {/* Configurar rutas y paginas*/}


                <Routes>
                     {/* paginas privadas */}
                    <Route exact path="/" element={
                        <RequireAuth isAuth={this.state.isAuth} >
                            <Home  checkToken={this.CheckToken}  />
                        </RequireAuth>
                    } />

                    <Route path="/top" element={
                        <RequireAuth isAuth={this.state.isAuth} >
                            <TopURL checkToken={this.CheckToken} />
                        </RequireAuth>
                    } />

                    <Route path="/short" element={
                        <RequireAuth isAuth={this.state.isAuth} >
                            <ShortUrlTable checkToken={this.CheckToken} />
                        </RequireAuth>
                    } />

                    <Route path="/perfil" element={
                        <RequireAuth isAuth={this.state.isAuth}>
                            <Perfil checkToken={this.CheckToken} />
                        </RequireAuth>
                    } />

                    <Route path="/cuenta" element={
                        <RequireAuth isAuth={this.state.isAuth}>
                            <ProtectedRoute isAdmin={this.state.isAdmin}>
                                <Cuentas checkToken={this.CheckToken} />
                            </ProtectedRoute>
                        </RequireAuth>
                       

                    } />

                    {/* paginas publicas */}
                    <Route exact path="/url/:idUrl" element={<Url />} />

                    <Route exact path="/auth/login" element={<Login LoginStatus={this.LoginStatus}  />} />

                    <Route exact path="/auth/out" element={<Out />} />

                    <Route exact path="/auth/protected" element={<NotAccess />} />

                    <Route exact path="/auth/require" element={<RequireLogin />} />

                    <Route path="*" element={<NotFound />} />

                </Routes>

            </BrowserRouter>
        )
    }
}

export default Router;