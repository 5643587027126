import React, { Component } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js/auto';

import axios from 'axios';

import HeaderInyected from '../../service/headers'
import { Line } from 'react-chartjs-2';

import { getBaseUrl, getUrlByDates } from '../../service/apiParameters';

import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
)

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
}



class GenerateUrl extends Component {


    constructor() {
        super();
        this.state = {
            chartData: {},
            month: new Date(),
        }
    }

    componentDidMount() {
        this.validate(this.state.month);
    }

    validate = (month) => {


        let quantity = [];
        let labels = [];
        axios.get(getBaseUrl() + getUrlByDates() + `?date=${month.toISOString().slice(0, 10)}`, HeaderInyected())
            .then(response => {
                if (response.data.code === 200) {

                    const data = response.data.results;

                    data.forEach(ele => {
                        quantity.push(parseInt(ele.count));
                        labels.push(ele._id);

                    });

                    this.setState({
                        chartData: {
                            datasets: [
                                {
                                    label: 'Los mas recientes',
                                    data: quantity,
                                    borderColor: 'rgb(53, 162, 235)',
                                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                    pointRadius: 6,
                                },
                            ],
                            labels,
                        }
                    }
                    )
                }

            }).catch(error => {
                localStorage.setItem('errorMessage', error.response.data.message);
                localStorage.setItem('errorLogin', JSON.stringify(error.response.data.errors));

                /*this.setState(
                    {
                        textErrorApi:true,
                        status:false  
                    });*/
            });

    }
    ChangeMonth(newValue){
         this.validate(newValue);
         this.setState({
            month: newValue
         })
    }

    render() {
        return (
            <div id='lastUrl'>
                <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                            label="MES Seleccionado"
                            value={this.state.month}
                            onChange={(newValue) => {
                                this.ChangeMonth(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            views={['month', 'year']}
                        />
                    </LocalizationProvider>
                </FormControl>

                <h4> Ultimos generados - Conteo</h4>

                {
                    Object.keys(this.state.chartData).length &&
                    <Line data={this.state.chartData} options={options} />
                }
            </div>
        );
    }
}

export default GenerateUrl;